import React from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

import { Box, Button, Chip, Container, Grid, Typography } from '@mui/material'

import { getCompany } from '../../api'
import { SupplierPageSkeleton } from '../../components/skeletons'
import { DefaultLayout as Layout } from '../../layouts'
import Block from '../../components/Block'

const cancellableSupplierSearch = async ({ queryKey }) => {
	const source = axios.CancelToken.source()
	const promise = getCompany(queryKey[1])
	promise.cancel = () => source.cancel()
	return promise
}

const useSupplierData = id =>
	useQuery(['supplier', id], cancellableSupplierSearch, {
		enabled: Boolean(id),
	})

const renderProductsAndServices = (productsAndServices, callback) => {
	const categories = Object.keys(productsAndServices)

	const results = categories.reduce((acc, category) => {
		const values = productsAndServices[category]
		return acc.concat(
			Object.keys(values).map(key => {
				const value = key.replace('_', ' ')
				return callback(key, value)
			})
		)
	}, [])

	return results
}

const Wrapper = ({ children, extra }) => {
	return (
		<Layout>
			<Block>
				<Container>
					<Box pt={8}>{children}</Box>
				</Container>
			</Block>
		</Layout>
	)
}

const SupplierPage = ({ params }) => {
	const { data, isLoading } = useSupplierData(params.id)

	if (isLoading) {
		return (
			<Wrapper>
				<SupplierPageSkeleton />
			</Wrapper>
		)
	}

	if (!data) {
		return null
	}

	return (
		<Wrapper>
			<Grid container>
				<Grid item sm={9}>
					<Box mb={2}>
						<Typography
							component={'h1'}
							gutterBottom
							variant={'h4'}
						>
							{data.name}
						</Typography>
						<Typography
							color={'textSecondary'}
							variant={'subtitle1'}
						>
							{data.advertText}
						</Typography>
					</Box>
					<Box display={'flex'}>
						<Button
							color={'primary'}
							size={'small'}
							variant={'contained'}
							sx={{
								mr: 1,
							}}
						>
							Get a quote
						</Button>
						<Button
							color={'primary'}
							size={'small'}
							variant={'contained'}
						>
							Contact us
						</Button>
					</Box>

					{data.contact.tel || data.contact.fax ? (
						<Box my={2}>
							<Typography>Tel: {data.contact.tel}</Typography>
							<Typography>Fax: {data.contact.fax}</Typography>
						</Box>
					) : null}

					{data.descr ? (
						<Box my={2}>
							<Typography variant={'overline'}>About</Typography>
							<Typography>{data.descr}</Typography>
						</Box>
					) : null}

					{data.productsAndServices ? (
						<Box my={2}>
							<Typography variant={'overline'}>
								Products and services
							</Typography>
							<Box>
								{renderProductsAndServices(
									data.productsAndServices,
									(key, value) => (
										<Chip
											key={key}
											label={value}
											size={'small'}
											variant={'outlined'}
											sx={{ mb: 0.5, mr: 0.5 }}
										/>
									)
								)}
							</Box>
						</Box>
					) : null}
				</Grid>
				<Grid item sm={3}>
					{/* <img
						src={'https://via.placeholder.com/200'}
						alt={'placeholder'}
					/> */}
				</Grid>
			</Grid>
		</Wrapper>
	)
}

export default SupplierPage
